import React from "react";

import Navigation from "../components/navigation";

export default () => (
    <Navigation>
        <div>
            <title>David Weik -About</title>
            <div>
                <h1 id="main-title" style={{ textAlign: "center" }}>
                    About me
                </h1>
                <h2>First Up</h2>
                <p style={{ margin: "0rem 1rem" }}>
                    By taking a look at my website you might have realised that
                    it is completly in english even though I'm using a German
                    domain. Well the reason for this is pretty simple I'm German
                    so I thought it appropriate to own a German domain but I
                    want my content to be accessible to the widest possible
                    audience so I publish my posts in English. If you are in
                    need of a German translation of one of my blog posts hit me
                    up on Twitter or E-Mail so we can talk about it. If you have
                    any suggestions, feedback, questions and or critic please
                    let me know.
                </p>
                <h2>Let's cut to the chase</h2>
                <p style={{ margin: "0rem 1rem" }}>
                    I love to lern and I love to program. As a kid I completly
                    lost myself in the world of Harry Potter and the magic of
                    that world fascinated me deeply. The ability to create
                    something out of nothing is just incredible. Well magic
                    sadly isn't real, but programming is pretty close!
                </p>
                <p style={{ margin: "0rem 1rem" }}>
                    For me programming is first and foremost a tool that let's
                    me express my ideas and creativity. From a simple website to
                    complex applications, nothing comes close in my mind to the
                    ability in terms of flexibility to turn my ideas into
                    something real and useable. That last point is especialy
                    important to me because what is the worth of an idea if it
                    doesn't reach anyone?
                </p>
                <p style={{ margin: "0rem 1rem 1rem 1rem" }}>
                    In addition to my two main areas of interesst (Web
                    Development (ECMAScript) and Machine Learning) I'm also
                    interessted in Data Science and Visualization. I find it
                    fascinating to combine different data source to generate
                    knew insights. Areas like UX do also interessted but mainly
                    as a vehicle to improve the accessibility of my creations
                    and lessen the mental burden on users.
                </p>
            </div>
        </div>
    </Navigation>
);
